<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="400">
            <v-card class="px-8 py-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">{{ title }}</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <form class="">
                            <div class="line mb-4">
                                <v-avatar color="white" size="45" class="mr-6">
                                    <v-img
                                        :lazy-src="getAvatar(avatar)"
                                        :src="getAvatar(avatar)"
                                    ></v-img>
                                </v-avatar>
                                <v-text-field
                                    label="昵称"
                                    v-model="name"
                                    required
                                    disabled
                                ></v-text-field>
                            </div>
                            <v-text-field
                                label="学生姓名"
                                v-model="real_name"
                            ></v-text-field>
                            <v-text-field
                                label="联系方式"
                                v-model="phone"
                            ></v-text-field>

                        </form>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { getAvatar } from "@/utils/util";

export default {
    name: "EditStudentDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(30) },
        real_name: { required },
    },

    data: function () {
        return {
            dialog: false,
            title: "",

            uid: "",
            avatar: "",
            name: "",
            real_name: "",
            number: "",
            phone: "",
            status: "",
        };
    },
    computed: {
        // realNameErrors() {
        //     const errors = [];
        //     if (!this.$v.real_name.$dirty) return errors;
        //     !this.$v.real_name.required && errors.push("请填写真实姓名");
        //     return errors;
        // },
    },
    created() {},
    activated() {},
    methods: {
        getAvatar,
        openDlg(title, student = null) {
            this.title = title

            if (student) {
                this.uid = student.uid
                this.avatar = student.avatar
                this.name = student.name
                this.real_name = student.real_name
                this.number = student.number
                this.phone = student.phone
                this.status = student.status
            } else {
                this.clear();
            }
            this.dialog = true;
        },
        clear() {
            this.$v.$reset()

            this.uid = ""
            this.avatar = ""
            this.name = ""
            this.real_name = ""
            this.number = ""
            this.phone = ""
            this.status = ""
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            // this.$v.$touch()
            // if (!this.$v.$invalid) {
                this.$emit("submit", {
                    uid: this.uid,
                    realName: this.real_name,
                    phone: this.phone,
                })
            // }
            this.dialog = false
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>
