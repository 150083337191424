<template>
    <v-container fluid class="px-8">
        <!-- 增加学生按钮 -->
        <div class="add-btn-row">
            <div>
                <v-btn
                    tile
                    dark
                    color="purple"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditCourseDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    增加学生
                </v-btn>
            </div>
            <div>
                <v-btn
                    tile
                    dark
                    color="light-blue darken-1"
                    class="font-weight-bold text-body-1"
                    @click.stop="viewStat(course.cid)"
                >
                    <v-icon left> mdi-qrcode </v-icon>
                    扫码录入
                </v-btn>
                <v-btn
                    tile
                    dark
                    color="purple lighten-1"
                    class="font-weight-bold text-body-1"
                    @click.stop="createUserAccount()"
                >
                    <v-icon left small> mdi-account-multiple </v-icon>
                    批量账号
                </v-btn>
                <v-btn
                    tile
                    dark
                    color="orange"
                    class="font-weight-bold text-body-1 mr-0"
                    @click.stop="openEditStudentDlg()"
                >
                    <v-icon left small> mdi-keyboard </v-icon>
                    手工录入
                </v-btn>
            </div>
        </div>

        <!-- 学生列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer mt-5"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
        >
            <template v-slot:no-data>
                暂无学生
            </template>
            <template v-slot:item.avatar="{ item }">
                <v-avatar color="white" size="36">
                    <v-img
                        :lazy-src="getAvatar(item.avatar)"
                        :src="getAvatar(item.avatar)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nickname="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name font-weight-medium" @click.stop="goto(item.class_id)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name" @click.stop="goto(item.class_id)" >
                        {{ getStudentName(item) }}
                    </div>
                </div>
            </template>
            <template v-slot:item.number="{ item }">
                <div>
                    {{ getStudentNumber(item.number) }}
                </div>
            </template>
            <template v-slot:item.phone="{ item }">
                <div>
                    {{ getStudentPhone(item.phone) }}
                </div>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="item.status == 2 ? 'light-green lighten-1' : 'grey lighten-1'" small dark 
                        class="font-weight-medium"
                >
                    {{ getStudentStatus(item.status) }}
                </v-chip>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="purple"
                            @click.stop="openEditStudentDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>补充学生信息</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.uid)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <EditStudentDlg ref="editStudentDlg" @submit="submitStudent" />

        <ConfirmDlg
            ref="delStudentConfirmDlg"
            title="删除学生信息"
            text="确认 删除 当前学生信息么？"
            keyword=" 删除 "
            @confirm="delStudentConfirm"
        />

    </v-container>
</template>

<script>
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import EditStudentDlg from '@/views/components/dialog/EditStudentDlg';
import { getAdminStudentList, updateAdminStudent, delAdminStudent, createUserAccount } from "@/api/admin";
import { getAvatar, getTypeFirstLetter } from "@/utils/util";
import { getTypeColor, getStudentStatus } from '@/utils/common';

export default {
    name: "StudentList",
    data() {
        return {
            topicId: "",
            headers: [
                { text: "头像", value: "avatar", align: "center", sortable: false, width: 60 },
                { text: "昵称", value: "nickname", align: "left", sortable: false, },
                { text: "学生姓名", value: "name",  align: "left", sortable: false, },
                { text: "账号", value: "number",   align: "center", sortable: false },
                { text: "联系方式", value: "phone", align: "center", sortable: false },
                // 学生状态：未付费/未激活/已激活
                // { text: "状态", value: "status",   align: "center", width: 80 },
                { text: "操作", value: "operate",  align: "center", sortable: false, width: 130 },
            ],
            datas: [],
        };
    },
    activated() {
        if (this.$route.query.id) {
            this.topicId = this.$route.query.id;
        }
        this.loadData();
    },
    methods: {
        getAvatar,
        getTypeColor,
        getTypeFirstLetter,
        getStudentStatus,
        getStudentName(item) {
            if (item.real_name != "") {
                return item.real_name
            }
            return item.name
        },
        getStudentNumber(number) {
            if (number == "") {
                return "暂无"
            }
            return number
        },
        getStudentPhone(phone) {
            if (phone == "") {
                return "未填写"
            }
            return phone
        },
        loadData() {
            getAdminStudentList().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            let pass;
            // this.$router.push({path: '/education-mgr/student/exercise', query: {id: id}});
        },
        openEditStudentDlg(student = null) {
            this.$refs.editStudentDlg.openDlg("补充学生信息", student);
        },
        openConfirmDlg(id) {
            this.$refs.delStudentConfirmDlg.openDlg(id);
        },
        submitStudent(student) {
            updateAdminStudent({ 
                studentId: student.uid,
                realName: student.realName,
                phone: student.phone
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    // 更新数据
                    for (let i in this.datas) {
                        if (this.datas[i].uid == student.uid) {
                            this.datas[i].real_name = student.realName
                            this.datas[i].phone = student.phone
                        }
                    }
                    
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "操作成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "操作未完成" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        delStudentConfirm(studentId) {
            delAdminStudent(studentId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].uid == studentId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除班级成功",
                    });

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除班级失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        createUserAccount() {
            createUserAccount({
                start: 61,
                end: 120,
            }).then((response) => {
                // console.log(response.data)
                this.$store.dispatch("web/SetAlert", {
                    type: "success",
                    msg: "创建成功",
                });

            }).catch(function (err) {
                console.log(err);
            });
        },
    },
    components: {
        ConfirmDlg,
        EditStudentDlg,
    }
};
</script>

<style lang="scss" scoped>
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.avatar {
    height: 45px;
    width: 45px;
    img {
        height: 100%;
        width: 100%;
    }
}
</style>